@import "../styles/sizes.module";
@import "../styles/colors.module";

.main-content-notifications {
  display: flex;
  justify-content: center;
  .notifications-component {
    width: 50%;
    @media (max-width: $mobile-width) {
      width: 90%;
    }
    z-index: 600;
    top: 100px;
    position: fixed;
  }
}
