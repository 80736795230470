@import "../shared-components/styles/default.colors.module";

// ## Couleurs spécifiques à l'application ##

$main-color: $light-blue-50;
$main-light-color: $neutral-200;
$neutral-main-background-color: $neutral-50;
$neutral-color: $neutral-300;
$primary-color: $light-blue-400;
$secondary-color: $dark-blue-800;
$secondary-light-color: $dark-blue-50;
$secondary-pink-color: $pink-700;
$side-menu-text-color: $dark-blue-850;
$side-menu-logo-background-color: $dark-blue-50;
$side-menu-background-color: $dark-blue-50;

// Ancien nommage, à supprimer une fois que tous les espaces auront fusionnés
$black-color: $neutral-800;
$danger-color: $error-500;
$grey-color: $neutral-600;
$success-color: $success-500;
$warn-color: #ff9900;
$white-color: #fafafa;
$disabled: $neutral-500;

:export {
  mainColor: $main-color;
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  secondaryPinkColor: $secondary-pink-color;
  secondaryLightColor: $secondary-light-color;
  whiteColor: $white;
}
