html {
  font-size: 16px !important;
}

body {
  font-family: "Lato", serif;
  margin: 0;
  padding: 0;
}

.ui.input.disabled {
  opacity: 1;
}

/*
 Fix clearable dropdown
 See https://github.com/fomantic/Fomantic-UI/issues/1259
 */
.ui.dropdown > .clear.icon:before {
  content: "\f00d";
}
