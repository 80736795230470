@import "../shared-components/styles/default.text-styles";

html {
  // ## Segment ##
  .segment {
    box-shadow: unset !important;

    .segment-body {
      background-color: $white;
    }
  }

  .form > .segment {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &:first-of-type {
      margin-top: 0.5rem;
    }

    @media (max-width: $mobile-width) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  // ## Label champs obligatoires ##
  .info-required-fields {
    color: $error-700;
    font-style: italic;
    text-align: right;

    &:last-of-type {
      margin-bottom: 0.5rem !important;
    }
  }

  span + .info-required-fields {
    @media (max-width: $mobile-width) {
      margin-top: 0.5rem !important;
    }
  }
}
